import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'counter']

  initialize () {
    this.update = this.update.bind(this)
  }

  connect () {
    this.update()
    this.inputTarget.addEventListener('input', this.update)
  }

  disconnect () {
    this.inputTarget.removeEventListener('input', this.update)
  }

  update () {
    if (this.count < 100) {
      this.counterTarget.innerHTML = this.count
      this.counterTarget.classList.remove('text-red-600')
    }
    else {
      this.counterTarget.innerHTML = this.count
      this.counterTarget.classList.add('text-red-600')
    }
  }

  get count () {
    return this.inputTarget.value.length
  }
}
