import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['method', 'type']

  connect() {
    let typeCount = 0;
    let typeWordsArray = ["download", "sign up", "subscribe"];


    let methodCount = 0;
    let methodWordsArray = ["Text", "SMS"];

    let methodText = this.methodTarget
    let typeText = this.typeTarget

    setInterval(function () {
      typeCount++;
      typeText.innerHTML = (typeWordsArray[typeCount % typeWordsArray.length])//.fadeIn(500);

      methodCount++;
      methodText.innerHTML = (methodWordsArray[methodCount % methodWordsArray.length])

    }, 1500);

  }
}
